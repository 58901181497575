import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import {
  Alert,
  Button,
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  TextField,
  LinearProgress,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { fetchData } from "../../../../fetchData";
import { phoneFormatting, formatDate } from "../../../../utils";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const columns: GridColDef[] = [
  {
    field: "description",
    headerName: "Evento",
    flex: 1,
    editable: true,
    renderCell: (params: any) => {
      let detail, newDescription;

      switch (params.row.description) {
        case "E-mail enviado!":
        case "E-mail recebido!":
        case "E-mail aberto!":
        case "Clicou em algum link no E-mail!":
        case "E-mail não foi entregue (Soft Bounce)!":
        case "E-mail não foi entregue (Hard Bounce)!":
        case "E-mail solicitou não receber mais notificações!":
        case "E-mail considerado SPAM ou Lixo Eletrônico!":
          detail = params.row.response.data.email.recipient.email;
          break;

        case "Anexos identificados, notificação liberada.":
          detail = `${params.row.response.attachments_count} documentos`;
          newDescription = "Anexos identificados";
          break;

        case "Contatos encontrados para realizar a notificação.":
          detail = params.row.response.map((person: any, index: number) => {
            return <div key={`person${index}`}>{person.nickname}</div>;
          });
          newDescription = "Contatos para notificação";
          break;

        case "Solicitação para notificação via E-mail concluída":
          detail = params.row.response.recipients.map(
            (recipient: any, index: number) => {
              return <div key={`recipient${index}`}>{recipient.email}</div>;
            },
          );
          newDescription = "Notificação por e-mail enviada!";
          break;
      }

      if (
        params.row.description.includes("Cliente") &&
        params.row.description.includes("localizado para notificação!")
      ) {
        newDescription = "Cliente encontrado";
        detail = "";
      }

      if (
        params.row.description.includes("Tipo de tarefa") &&
        params.row.description.includes("encontrado para notificação")
      ) {
        newDescription = "Tipo de tarefa liberado";
        detail = `${params.row.response.name}`;
      }

      if (
        params.row.description.includes(
          "Solicitação para notificação via SMS concluída",
        )
      ) {
        const phone = params.row.description.replace(/\D/g, "");
        newDescription = "SMS";

        detail = `${phoneFormatting(phone.slice(2))}`;
      }

      return (
        <Stack
          direction="column"
          alignContent="flex-start"
          width="100%"
          padding="5px"
        >
          <Stack direction="row">
            <Typography
              variant="caption"
              fontWeight="bold"
              flex={1}
              sx={{ overflow: "hidden" }}
            >
              {newDescription ? newDescription : params.row.description}
            </Typography>
            <Typography variant="caption">
              {formatDate(params.row.registered_at, "D/M/Y H:i")}
            </Typography>
          </Stack>
          {detail && <Typography variant="caption">{detail}</Typography>}
        </Stack>
      );
    },
  },
];

const RRTaskLog = ({ taskLog }: { taskLog: any }) => {
  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [activityLogs, setActivityLogs] = useState<any>([]);
  const [taskID, setTaskID] = useState<number | string>("");

  const onSubmit = async () => {
    if (!taskID) return;

    setLoading(true);
    setNoResults(false);

    try {
      const result = await fetchData(
        `/histories/runrunit/tasks/${taskID}/acitivity/logs`,
        {
          method: "GET",
        },
      );

      console.log(result);

      if (result.length === 0) setNoResults(true);
      setActivityLogs(
        result.map((row: any, index: number) => ({
          ...row,
          id: `task-${row.id}-${index}`,
        })),
      );
    } catch (error) {
      console.log(error);
      setError(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!state) {
      setNoResults(false);
      setError(false);
      setTaskID("");
      setActivityLogs([]);
    }
  }, [state]);

  useEffect(() => {
    if (taskLog.get) {
      setTaskID(taskLog.get);
      onSubmit();
      setState(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskLog.get]);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        sx={{ flex: 1 }}
        onClick={() => {
          setState(true);
        }}
      >
        Log de atividade
      </Button>
      <Drawer
        anchor="right"
        open={state}
        onClose={() => {
          setState(false);
        }}
      >
        <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
          <Stack height="100%" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                direction="row"
                spacing={1}
                sx={{ flex: 1 }}
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  sx={{ width: 170, wordWrap: "break-word" }}
                >
                  Log de atividade
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  setState(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Stack direction="row" spacing={1}>
              <TextField
                label="Número da tarefa"
                value={taskID}
                onChange={(e: any) => {
                  setTaskID(e.target.value);
                }}
                size="small"
                sx={{ flex: 1 }}
              />
              <Button size="small" variant="contained" onClick={onSubmit}>
                <KeyboardArrowRightIcon />
              </Button>
            </Stack>
            {loading && <LinearProgress />}
            {noResults && (
              <Alert
                severity="warning"
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: 1,
                }}
                icon="¯\_(ツ)_/¯"
              >
                <Typography variant="body2" textAlign="center">
                  Nenhum registro encontrado para esta tarefa.
                </Typography>
              </Alert>
            )}
            {error && (
              <Alert
                severity="error"
                sx={{
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: 1,
                }}
              >
                <Typography variant="h4" textAlign="center" sx={{ mb: 1 }}>
                  Ops!
                </Typography>
                <Typography variant="body2" textAlign="center">
                  Um erro inesperado ocorreu, tente novamente mais tarde.
                </Typography>
              </Alert>
            )}
            {activityLogs.length > 0 && (
              <DataGrid
                rows={activityLogs}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 100,
                    },
                  },
                }}
                hideFooter
                columnHeaderHeight={0}
                sx={{ flex: 1 }}
                getRowHeight={() => "auto"}
              />
            )}
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default RRTaskLog;
